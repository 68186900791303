$(function () {

  const $window　=　$(window);
  const breakpoint = 768;
  const duration = 500;

  function setlinearNav() {
    const $linearNavLimit = $('.js-linearNav-limit');

    $window.on('load resize', function () {
      const windowWidth = $window.width();

      const isDeviceSp = windowWidth < breakpoint;
      if (isDeviceSp) {
        $linearNavLimit.trunk8({
          lines: 2,
          fill: '…',
          tooltip: false,
        });
      } else {
        $linearNavLimit.trunk8({
          lines: 1,
          fill: '…',
          tooltip: false,
        });
      }
    });
  }

  const isPageNews = $('#news').length;
  if (isPageNews) {
    setlinearNav();
  }

});