$(function () {

  const $window　=　$(window);
  const breakpoint = 768;
  const duration = 500;

  function setNav() {
    const $navBtn = $('.js-nav-btn');
    const $navContent = $('.js-nav-content');
    let windowScroll;
    let navOpen = false;

    $navBtn.click(function () {
      if (navOpen === false) {
        windowScroll = $window.scrollTop();
        $('body').addClass('is-fixed').css({'top': -windowScroll});
        $navBtn.addClass('is-open');
        $navContent.fadeIn(duration);
        navOpen = true;
      } else {
        $('body').removeClass('is-fixed').css({'top': ''});
        $window.scrollTop(windowScroll);
        $navBtn.removeClass('is-open');
        $navContent.fadeOut(duration);
        navOpen = false;
      }
    });
  }

  function smoothScroll() {
    $('a[href^="#"]').click(function () {
      const href = $(this).attr("href");
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top;
      $('html, body').animate({scrollTop: position}, duration);
      return false;
    });
  }

  setNav();
  smoothScroll();

});