$(function () {

  const $window　=　$(window);
  const breakpoint = 768;
  const duration = 500;

  function setmainVisual() {
    const $mainVisualSlider = $('.js-mainVisual-slider');

    $mainVisualSlider.slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      dots: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 'calc((100% - 1000px) / 2)',
      responsive: [
        {
          breakpoint: breakpoint,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '',
          }
        }
      ]
    });
    
    $mainVisualSlider.on('inview', function (event, isInView) {
      const $this = $(this);

      if (isInView) {
        $this.slick('slickPlay');
      } else {
        $this.slick('slickPause');
      }
    });
  }

  const isPageTop = $('#top').length;
  if (isPageTop) {
    setmainVisual();
  }

});