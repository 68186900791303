$(function () {

  function validation() {

    const $submit = $('.js-contact-submit');
    const $required = $('.js-require');
    const $name = $('.js-contact-name');
    const $kana = $('.js-contact-kana');
    const $mail = $('.js-contact-mail');
    const $agree = $('.js-agree');
    const contactTopPosition = $('.contact').offset().top

    // エラーフラグ
    let error;

    // エラーメッセージ
    const requiredMessageHtml = "<p class='contact-item__error'>必須項目です。</p>";
    const nameMessageHtml = "<p class='contact-item__error'>お名前を正しく入力してください。</p>";
    const kanaMessageHtml = "<p class='contact-item__error'>フリガナを正しく入力してください。</p>";
    const mailMessageHtml = "<p class='contact-item__error'>メールアドレスを正しく入力してください。</p>";

    //正規表現
    const isfullWitdhCharacter = /^[ぁ-んァ-ヶー一-龠a-zA-Z]+$/;
    const isKana = /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/;
    const isMail = /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/;

    // 同意チェック
    $agree.on('change', function(event) {
      if($(this).prop('checked')){
        $submit.prop('disabled', false);
      } else {
        $submit.prop('disabled', true);
      }
    })

    // 必須チェック
    function requiredCheck() {
      $required.each(function() {
        let value = $(this).val();

        if (value === "") {
          error = true;
          const target = $(this).closest($('.contact-item__data'));

          target.append(requiredMessageHtml);
          target.addClass('is-error');
        }
      });
    }

    // 名前（全角）チェック
    function nameCheck() {
      let value = $name.val();
      if (!value.match(isfullWitdhCharacter)) {
        error = true;
        const target = $name.closest($('.contact-item__data'))

        target.addClass('is-error');
        target.append(nameMessageHtml)
      }
    }

    // フリガナチェック
    function kanaCheck() {
      let value = $kana.val();
      if (!value.match(isKana)) {
        error = true;
        const target = $kana.closest($('.contact-item__data'));

        target.addClass('is-error');
        target.append(kanaMessageHtml);
      }
    }

    // メールチェック
    function mailCheck() {
      let value = $mail.val();

      if (!value.match(isMail)) {
        error = true;
        const target = $mail.closest($('.contact-item__data'))

        target.addClass('is-error');
        target.append(mailMessageHtml);
      }
    }

    $submit.on('click', function() {
      error = false;
      $('.contact-item__error').remove();
      $('.contact-item__data').removeClass('is-error')

      requiredCheck();
      kanaCheck();
      mailCheck();
      nameCheck();

      if (error) {
        $(window).scrollTop(contactTopPosition)
        return false;
      }
    });
  }

  const isPageContact = $('#contact').length;
  if (isPageContact) {
    validation();
  }

});